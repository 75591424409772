// src/pages/AiPage.js
import React from 'react';
import { Box, Typography, Card, CardContent, CardActions, Button } from '@mui/material';

function AiPage() {
  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        생성형 AI
      </Typography>
      <Typography variant="body1" sx={{ color: '#666', mb: 4 }}>
        조직에서 활용 가능한 AI 모델 및 생성형 도구를 설정하고 관리합니다.
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: 2,
        }}
      >
        <Card>
          <CardContent>
            <Typography variant="h6">AI 모델 활용</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              문서 작성, 이메일 작성 보조 등 AI 기능 사용 권한을 설정합니다.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" size="small">권한 설정</Button>
          </CardActions>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h6">AI 보안</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              데이터 학습 정책 및 AI 모델 접근 제한을 정의합니다.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" size="small">보안 정책</Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
}

export default AiPage;
