// src/pages/PaymentPage.js
import React from 'react';
import { Box, Typography, Card, CardContent, CardActions, Button } from '@mui/material';

function PaymentPage() {
  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        결제
      </Typography>
      <Typography variant="body1" sx={{ color: '#666', mb: 4 }}>
        구독 플랜, 결제 수단, 인보이스 내역 등 결제 관련 정보와 설정을 관리하세요.
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: 2,
        }}
      >
        <Card>
          <CardContent>
            <Typography variant="h6">구독 플랜</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              사용 중인 플랜 확인, 업그레이드/다운그레이드 옵션 확인.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" size="small">플랜 변경</Button>
          </CardActions>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h6">결제 수단</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              카드/계좌를 추가, 변경, 삭제 및 자동결제 옵션 설정.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" size="small">결제 수단 관리</Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
}

export default PaymentPage;
