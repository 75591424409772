// src/pages/AccountPage.js
import React from 'react';
import { Box, Typography, Card, CardContent, CardActions, Button } from '@mui/material';

function AccountPage() {
  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        계정
      </Typography>
      <Typography variant="body1" sx={{ color: '#666', mb: 4 }}>
        사용자 프로필, 보안 설정, 로그인 옵션 등을 확인하고 관리합니다.
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: 2,
        }}
      >
        <Card>
          <CardContent>
            <Typography variant="h6">프로필 정보</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              사용자 이름, 사진, 연락처 정보 등을 업데이트하세요.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" size="small">프로필 편집</Button>
          </CardActions>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h6">보안 설정</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              2단계 인증, 비밀번호 변경, 계정 복구 옵션을 관리하세요.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" size="small">보안 설정</Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
}

export default AccountPage;
