// src/pages/HomePage.js
import React from 'react';
import { Typography, Box, Card, CardContent, CardActions, Button } from '@mui/material';

function HomePage() {
  return (
    <Box>
      {/* 상단 섹션 */}
      <Typography variant="h5" sx={{ mb: 2 }}>
        Not all truths compute.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, color: '#666' }}>
        Aporium encourages exploration over solution, and insight over certainty.
      </Typography>

      {/* 대시보드 카드들 */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: 2,
        }}
      >
        {/* 첫 번째 카드 */}
        <Card sx={{ minHeight: 180 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Problem Solving
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Explore the problem space and identify potential solutions.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" size="small">Challenge</Button>
            <Button size="small">#########</Button>
          </CardActions>
        </Card>

        {/* 다른 카드들... */}
      </Box>

      {/* 추가 섹션... */}
    </Box>
  );
}

export default HomePage;
