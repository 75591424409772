import React, { useState, useEffect, lazy, Suspense, useRef } from 'react';
import { Box, Typography, Tabs, Tab, IconButton } from '@mui/material';
import { githubLight } from '@uiw/codemirror-theme-github';
import { langs } from '@uiw/codemirror-extensions-langs';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const MonacoEditor = lazy(() => import('@monaco-editor/react'));


function DirectoryPage() {
  const [code, setCode] = useState(`# 여기에 파이썬 코드를 작성하세요\nprint("Hello, Code Editor!")`);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isMobileUI, setIsMobileUI] = useState(false);
  const [mobileTab, setMobileTab] = useState(0);
  const [fade, setFade] = useState(false);
  const monacoRef = useRef(null);
  
  useEffect(() => {
    const checkResponsiveUIAndLayout = () => {
      const isDeviceMobile = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent);
      const screenRatio = window.innerWidth / window.screen.width;
      const isSmallScreen = screenRatio <= 0.45;
      const newIsMobileUI = isDeviceMobile || isSmallScreen;
      
      setIsMobileDevice(isDeviceMobile);
      setIsMobileUI(newIsMobileUI);
  
      // 만약 PC 환경이고 Monaco가 있을 경우 layout 재조정
      if (!isDeviceMobile && monacoRef.current) {
        monacoRef.current.layout();
        console.log('PC 환경에서 Monaco layout 재조정');
      }
    };
  
    checkResponsiveUIAndLayout(); // 초기 한 번 실행
  
    window.addEventListener('resize', checkResponsiveUIAndLayout);
    return () => window.removeEventListener('resize', checkResponsiveUIAndLayout);
  }, []); 
  useEffect(() => {
    if (monacoRef.current) {
      monacoRef.current.layout();
    }
  }, [mobileTab]);

  const [result, setResult] = useState(null);
  const [showResult, setShowResult] = useState(false);

  const handleEditorMount = (editor) => {
    monacoRef.current = editor;
    editor.layout();
  };

  const runCode = async () => {
    try {
      const res = await fetch('/api/execute', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code, input: '' }),
      });
  
      const data = await res.json();
      setResult(data);
      setShowResult(true);
      setFade(true); // fade-in
  
      setTimeout(() => setFade(false), 2500); // fade-out 시작
      setTimeout(() => setShowResult(false), 3000); // 숨김
    } catch (error) {
      setResult({
        status: 'RuntimeError',
        stderr: error.toString(),
        stdout: '',
        exit_code: -1
      });
      setShowResult(true);
      setFade(true);
      setTimeout(() => setFade(false), 2500);
      setTimeout(() => setShowResult(false), 3000);
    }
  };
  
  const renderProblem = () => (
    <Box
      sx={{
        maxWidth: '100%',
        bgcolor: 'white',
        borderRadius: 3,
        boxShadow: 2,
        p: 3,
        height: '100%',
        border: '1px solid #eee',
        background: 'linear-gradient(to bottom, #fff, #f9f9f9)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
        📘 Bubble Sort
      </Typography>
      <Typography variant="body1" sx={{ flex: 1 }}>
        다음 파이썬 코드를 작성하세요: <strong>"Hello, Code Editor!"</strong>를 출력하세요.
      </Typography>
    </Box>
  );

  const renderEditor = () => (
    <Box
      sx={{
        position: 'relative',  // ✅ 중요: 팝업 포지션 기준
        bgcolor: 'white',
        borderRadius: 3,
        boxShadow: 2,
        p: 3,
        height: '100%',
        border: '1px solid #eee',
        background: 'linear-gradient(to bottom, #fff, #f9f9f9)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        ...(isMobileUI && !isMobileDevice && {
          boxSizing: 'border-box',
        })
      }}
    >
      {/* 파일명 + 실행 버튼 */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography variant="subtitle2" color="text.secondary">main.py</Typography>
        <IconButton
          onClick={runCode}
          sx={{ color: '#1976d2', p: 0.5 }}
        >
          <PlayArrowIcon />
        </IconButton>
      </Box>
  
      {/* 에디터 */}
      <Box sx={{ flex: 1 }}>
        {isMobileDevice ? (
          <CodeMirrorEditorMobile code={code} setCode={setCode} />
        ) : (
          <Suspense fallback={<div>Loading Editor...</div>}>
            <MonacoEditor
              language="python"
              value={code}
              theme="vs-light"
              onMount={handleEditorMount}
              options={{
                fontSize: 14,
                fixedOverflowWidgets: true,
                renderLineHighlight: "none",
                minimap: { enabled: false },
                overviewRulerBorder: false,
                scrollBeyondLastLine: false,
                scrollbar: { vertical: 'auto', horizontal: 'auto' },
                automaticLayout: false,
                lineNumbersMinChars: 2,
              }}
              onChange={(value) => setCode(value || '')}
            />
          </Suspense>
        )}
      </Box>
  
      {/* 상태바 */}
      <Box sx={{ mt: 1, pt: 1, fontSize: 12, color: 'gray', textAlign: 'right' }}>
        Language: Python · UTF-8
      </Box>
  
      {/* ✅ 실행 결과 팝업 */}
      {showResult && result && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 12,
            left: 12,
            right: 12,
            bgcolor: result.status === 'Success' ? 'rgba(56, 142, 60, 0.85)' : 'rgba(211, 47, 47, 0.85)',
            color: 'white',
            p: 2,
            borderRadius: 2,
            fontSize: 14,
            zIndex: 10,
            boxShadow: 3,
            pointerEvents: 'none',
            opacity: fade ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
          }}
        >
          <div><strong>Status:</strong> {result.status}</div>
          {result.stdout && <div><strong>Output:</strong> {result.stdout}</div>}
          {result.stderr && <div><strong>Error:</strong> {result.stderr}</div>}
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ width: '100%', height: 'calc(100vh - 150px)', bgcolor: '#ffffff' }}>
      {isMobileUI ? (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column'}}>
          <Tabs
            value={mobileTab}
            onChange={(e, newValue) => setMobileTab(newValue)}
            centered
          >
            <Tab label="문제" />
            <Tab label="에디터" />
          </Tabs>
          <Box sx={{ flex: 1, px: 2, py: 2 }}>
            {mobileTab === 0 ? renderProblem() : renderEditor()}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            gap: 2,
            p: 2,
            bgcolor: '#ffffff',
            boxSizing: 'border-box',
          }}
        >
          <Box sx={{ width: '50%', height: '100%' }}>
            {renderProblem()}
          </Box>
          <Box sx={{ width: '50%', height: '100%' }}>
            {renderEditor()}
          </Box>
        </Box>
      )}
    </Box>
  );
}

function CodeMirrorEditorMobile({ code, setCode }) {
  const [EditorComponent, setEditorComponent] = useState(null);
  const extension = langs.python?.();

  useEffect(() => {
    import('@uiw/react-codemirror').then((mod) => {
      setEditorComponent(() => mod.default);
    });
  }, []);

  if (!EditorComponent || !extension) {
    return <div>Loading...</div>;
  }

  return (
    <EditorComponent
      value={code}
      height="100%"
      extensions={[extension]}
      theme={githubLight}
      onChange={(value) => setCode(value)}
    />
  );
}

export default DirectoryPage;
