// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';

// 각 페이지 컴포넌트 import
import HomePage from './pages/HomePage';
import DirectoryPage from './pages/DirectoryPage';
import DevicePage from './pages/DevicePage';
import AppsPage from './pages/AppsPage';
import AiPage from './pages/AiPage';
import PaymentPage from './pages/PaymentPage';
import AccountPage from './pages/AccountPage';

function App() {
  return (
    <Router>
      {/* Layout 안에 헤더, 사이드바, MainContent 등 공통 UI가 들어있고,
          Outlet 자리에 각 페이지가 렌더링 됩니다. */}
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* / 경로에 해당하는 페이지 */}
          <Route index element={<HomePage />} />
          {/* /directory */}
          <Route path="directory" element={<DirectoryPage />} />
          <Route path="device" element={<DevicePage />} />
          <Route path="apps" element={<AppsPage />} />
          <Route path="ai" element={<AiPage />} />
          <Route path="payment" element={<PaymentPage />} />
          <Route path="account" element={<AccountPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
