// src/pages/AppsPage.js
import React from 'react';
import { Box, Typography, Card, CardContent, CardActions, Button } from '@mui/material';

function AppsPage() {
  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        앱
      </Typography>
      <Typography variant="body1" sx={{ color: '#666', mb: 4 }}>
        조직 내에서 사용 중인 앱을 확인하고, 설치 권한 및 설정을 관리합니다.
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: 2,
        }}
      >
        <Card>
          <CardContent>
            <Typography variant="h6">앱 관리</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              설정 및 접근 권한 제어.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" size="small">앱 설정</Button>
          </CardActions>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h6">서드파티 앱</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              외부 개발자가 제공하는 앱과의 연동 설정.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" size="small">연동 관리</Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
}

export default AppsPage;
