// src/components/Layout.js
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  InputBase,
  Avatar,
  Menu,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DevicesIcon from '@mui/icons-material/Devices';
import AppsIcon from '@mui/icons-material/Apps';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonIcon from '@mui/icons-material/Person';
import AdbIcon from '@mui/icons-material/Adb';

const drawerWidth = 200;

const MainContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile',
})(({ theme, open, isMobile }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginLeft: isMobile ? 0 : open ? 0 : -100,
  marginRight: isMobile ? 0 : open ? 0 : 100,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

function Layout() {
  const [open, setOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [isMobile, setIsMobile] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const checkMobileMode = () => {
      const userAgent = navigator.userAgent;
      const isDeviceMobile = /Mobi|Android|iPhone|iPad/i.test(userAgent);
      const screenRatio = window.innerWidth / window.screen.width;
      const isSmallWindow = screenRatio <= 0.55;
  
      // 둘 중 하나라도 해당되면 모바일로 간주
      setIsMobile(isDeviceMobile || isSmallWindow);
    };
  
    checkMobileMode();
    window.addEventListener('resize', checkMobileMode);
    return () => window.removeEventListener('resize', checkMobileMode);
  }, []);

  React.useEffect(() => {
    const preventZoom = (e) => {
      if (
        (e.ctrlKey || e.metaKey) &&
        (e.key === '+' || e.key === '-' || e.key === '=' || e.key === '0')
      ) {
        e.preventDefault();
      }
    };
  
    const preventWheelZoom = (e) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
      }
    };
  
    window.addEventListener('keydown', preventZoom, { passive: false });
    window.addEventListener('wheel', preventWheelZoom, { passive: false });
  
    return () => {
      window.removeEventListener('keydown', preventZoom);
      window.removeEventListener('wheel', preventWheelZoom);
    };
  }, []);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleMenuClose();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: '#fff',
          color: '#444',
          boxShadow: 'none',
          borderBottom: '1px solid #ddd',
        }}
      >
        <Toolbar>
          <IconButton edge="start" onClick={isMobile ? handleMenuClick : () => setOpen(!open)} sx={{ mr: 2, color: '#444' }}>
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#f1f3f4',
              borderRadius: '8px',
              px: 2,
              py: 0.5,
              flexGrow: 1, // 너비 채우기
              maxWidth: { xs: '100%', sm: '400px' },
              mr: 2,
            }}
          >
            <SearchIcon sx={{ mr: 1, color: '#888' }} />
            <InputBase
              placeholder="사용자, 그룹 또는 설정 검색"
              sx={{ width: '100%' }}
              inputProps={{
                style: {
                  lineHeight: '1.5',
                  padding: '8px 0',
                },
              }}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Avatar sx={{ backgroundColor: '#ddd', color: '#555' }}>A</Avatar>
        </Toolbar>
      </AppBar>

      {!isMobile && (
        <Drawer
          variant="persistent"
          open={open}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              borderRight: '1px solid #ddd',
            },
          }}
        >
          <Toolbar />
          <List>
            <ListItemButton onClick={() => navigate('/')}> <ListItemIcon><HomeIcon /></ListItemIcon> <ListItemText primary="홈" /> </ListItemButton>
            <ListItemButton onClick={() => navigate('/directory')}> <ListItemIcon><AccountTreeIcon /></ListItemIcon> <ListItemText primary="디렉터리" /> </ListItemButton>
            <ListItemButton onClick={() => navigate('/device')}> <ListItemIcon><DevicesIcon /></ListItemIcon> <ListItemText primary="기기" /> </ListItemButton>
            <ListItemButton onClick={() => navigate('/apps')}> <ListItemIcon><AppsIcon /></ListItemIcon> <ListItemText primary="앱" /> </ListItemButton>
            <ListItemButton onClick={() => navigate('/ai')}> <ListItemIcon><AdbIcon /></ListItemIcon> <ListItemText primary="생성형 AI" /> </ListItemButton>
            <ListItemButton onClick={() => navigate('/payment')}> <ListItemIcon><PaymentIcon /></ListItemIcon> <ListItemText primary="결제" /> </ListItemButton>
            <ListItemButton onClick={() => navigate('/account')}> <ListItemIcon><PersonIcon /></ListItemIcon> <ListItemText primary="계정" /> </ListItemButton>
          </List>
        </Drawer>
      )}

      {/* 모바일 전용 메뉴 */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleNavigate('/')}>홈</MenuItem>
        <MenuItem onClick={() => handleNavigate('/directory')}>디렉터리</MenuItem>
        <MenuItem onClick={() => handleNavigate('/device')}>기기</MenuItem>
        <MenuItem onClick={() => handleNavigate('/apps')}>앱</MenuItem>
        <MenuItem onClick={() => handleNavigate('/ai')}>생성형 AI</MenuItem>
        <MenuItem onClick={() => handleNavigate('/payment')}>결제</MenuItem>
        <MenuItem onClick={() => handleNavigate('/account')}>계정</MenuItem>
      </Menu>

      <MainContent open={!isMobile && open} isMobile={isMobile}>
        <Toolbar />
        <Outlet />
      </MainContent>
    </Box>
  );
}

export default Layout;
