// src/pages/DevicePage.js
import React from 'react';
import { Box, Typography, Card, CardContent, CardActions, Button } from '@mui/material';

function DevicePage() {
  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        기기
      </Typography>
      <Typography variant="body1" sx={{ color: '#666', mb: 4 }}>
        조직에서 등록된 기기의 상태와 정책을 한 눈에 확인하고 관리합니다.
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: 2,
        }}
      >
        <Card>
          <CardContent>
            <Typography variant="h6">모바일 기기 현황</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              등록된 스마트폰, 태블릿의 보안 상태 및 정책 적용 확인.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" size="small">기기 목록</Button>
          </CardActions>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h6">엔드포인트 관리</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              데스크톱/노트북에 대한 정책 적용, 원격 삭제, 암호화 설정 등.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" size="small">정책 설정</Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
}

export default DevicePage;
